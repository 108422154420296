import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  fetchDealerships(page: number, perPage: number, search: string) {
    return Http.get('/dealerships', {
      params: {
        page,
        perPage,
        search
      }
    })
  }
}

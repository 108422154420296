
// import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'

import { Mixins, Watch } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import Dealerships from '@/apis/Dealerships'
import { Http } from '@/apis/Http'

@Component({
  name: 'DealershipsList',
  components: {
  }
})

export default class NewSearchRequest extends Mixins(TableMixin) {
  @Watch('pagination.currentPage')
  onPaginationChange(val: any, oldVal: any) {
    // иначе происходит 2 запроса подряд при первом заходе
    if (oldVal !== null) {
      this.getPartners(val, this.perPage)
    }
  }

  @Watch('search')
  onSearchChange() {
    this.getPartners(1, this.perPage)
  }

  perPage = 10
  simpleTable = true
  dealershipsList = {
    headers: [
      {
        text: 'Наименование',
        sortable: false,
        align: 'start',
        value: 'name'
      },
      {
        text: 'Расположение',
        sortable: false,
        align: 'start',
        value: 'address'
      },
      {
        text: 'Марки авто',
        sortable: false,
        align: 'start',
        value: 'brands',
        width: 300
      },
      {
        text: 'Статус',
        sortable: false,
        value: 'isFavorite'
      },
      {
        text: '',
        sortable: false,
        value: 'switch'
      }
    ],
    items: []
  }

  isLoading = true

  pagination = {
    total: null,
    currentPage: null,
    firstPage: null,
    lastPage: null,
    firstPageUrl: null,
    lastPageUrl: null,
    nextPageUrl: null,
    perPage: null
  }

  created() {
    this.getPartners(1, this.perPage)
    Http.get('/user-enter-dealerships-list')
  }

  getPartners(page: number, perPage: number) {
    Dealerships.fetchDealerships(page, perPage, this.search)
      .then(response => {
        this.dealershipsList.items = Object.values(response.data.data)
        this.pagination = this.fillPagination(response.data)
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Что-то пошло не так! Попробуйте позже')
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  fillPagination(data: any) {
    return {
      total: data.total,
      currentPage: data.current_page,
      firstPage: data.first_page ?? null,
      lastPage: data.last_page ?? null,
      firstPageUrl: data.first_page_url ?? null,
      lastPageUrl: data.last_page_url ?? null,
      nextPageUrl: data.next_page_url ?? null,
      perPage: data.per_page ?? 10
    }
  }

  get search() {
    return this.$store.state.common.searchLcDealerships
  }

  set search(item) {
    this.$store.state.common.searchLcDealerships = item
  }

  countOrders(number: number): string {
    return stringUtils.pluralForm(number, ['автосалон', 'автосалона', 'автосалонов'])
  }

  setFavorite(item: any) {
    Http.post('/dealerships/set-favorite', {
      dealership_id: item.id,
      is_favorite: item.isFavorite
    })
  }
}
